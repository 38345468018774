// variables.
@grid-gutter-width: 3%; // Small screen / tablet
@container-tablet: 100%; // Medium screen / desktop
@container-desktop: 100%; // Large screen / wide desktop
@container-large-desktop: 100%;
@tb-dark-blue: #023;
@tb-mid-blue: #467;
@tb-blue: #357;
@tb-light-blue: #689;
@tb-lighter-blue: #9ac;
@tb-orange: #fa0;
@tb-dark-orange: #e80;
@tb-dark-border-orange: #b60;
@tb-border-gray: #ddd;
@tb-button-gray: #e4e8ea;
@tb-background-gray: #f4f8fa;
@rss-orange: #f60;
@twitter-blue: #49f;
@facebook-blue: #359;
@padding-xsmall-vertical: 3px;
@padding-xsmall-horizontal: 5px;
@border-radius-xsmall: 3px;
@font-size-xsmall: 12px;
@font-size-small: 14px;
@grid-float-breakpoint: 768px;
@screen-tiny-min: 480px; // allows for media queries for TINY devices
@callout-padding: 20px;
@callout-border-radius: 0;
@callout-border: @gray-lighter;
@callout-info-bg: #f4f8fa;
@callout-info-text: @state-info-text;
@callout-info-border: @state-info-border;
@callout-warning-bg: #faf8f0;
@callout-warning-text: @state-warning-text;
@callout-warning-border: @state-warning-border;
@callout-success-bg: #f0faf0;
@callout-success-text: @state-success-text;
@callout-success-border: @state-success-border;
@callout-danger-bg: #fdf7f7;
@callout-danger-text: @state-danger-text;
@callout-danger-border: @state-danger-border;

.margin-top-lg {
	margin-top: 40px;
}

.margin-top {
	margin-top: 20px;
}

.margin-right {
	margin-right: 15px;
}

.margin-left {
	margin-left: 15px;
}

.vertical-middle {
	vertical-align: middle;

	.fa,
	p {
		vertical-align: middle;
	}
}

.no-padding {
	padding: 0;
}
// overrides

.btn-group .btn,
.btn-group-vertical .btn {
	transition: background 0.2s;
}

.btn-default {
	background-color: @tb-button-gray;
}

.btn-default:hover {
	background-color: darken(@tb-button-gray,10%);
}

.btn-tiny {
	padding: 5px;
	font-size: 11px;
}

.panel-heading {
	h1,
	h2,
	h3,
	h4 {
		margin: 0;
	}
}

.panel-footer {
	.pagination {
		margin: 0;
	}
}

blockquote {
	p {
		font-family: 'Arapey',Georgia,Cambria,"Times New Roman",Times,serif;
		color: #888;
		clear: none;
		margin-top: none;
		display: inline;
		width: auto;
		font-style: italic;
		font-size: 24px;
	}
	display: table;
	clear: none;
	width: auto;
	padding: none;
}

.tagline {
	font-family: 'Courgette','Arapey',Georgia,Cambria,"Times New Roman",Times,serif;
	color: #fff;
	margin: 20px auto -10px;
	font-size: 32px;
	font-weight: 400;
}

.blog_panorama {
	margin-bottom: 10px;
}

#panorama-container {
	overflow: hidden!important;
	margin-bottom: 10px;

	.panorama-padder {
		display: table;
		width: auto;

		#panorama-container {
			display: table-cell;
			vertical-align: middle;
			text-align: center;

			.panorama {
				img {
					max-width: 100%;
				}
			}
		}
	}
}

label {
	font-weight: 400;
	color: @tb-dark-orange;
}

.ptl {
	position: relative;
	max-width: 100%;
	overflow: hidden;
	display: block;
}

.display {
	border: 1px solid #bbb;
	display: inline-block;
	text-align: center;
	padding: 4px;
	background: #fff;
}

.protector {
	position: absolute;
	left: 0;
}
// Mixins

.nav-shadow(@color) {
	#gradient > .vertical-three-colors(lighten(@color, 5%), @color, 5%, darken(@color, 5%));
}

.header-shadow(@color) {
	#gradient > .vertical-three-colors(lighten(@color, 0%), @color, 92%, darken(@color, 7%));
}
// Layout

.tb-wrapper-band {
	width: 100%; //	background:url(/pix/something-funky.png) repeat;
	background-color: @tb-light-blue;

	&.tb-dark-blue {
		background: @tb-dark-blue;
	}

	&.bordered {
		border: 1px solid @tb-border-gray;
		border-width: 1px 0;
	}

	.container {
		.navbar {
			margin: 0;
			min-height: 0;
		}
	}

	ul {
		margin: 10px;

		a {
			color: #eee;
			line-height: 2em;

			i {
				color: #eee;

				&.fa {
					margin-right: 10px;
				}
			}
		}
	}
	margin: 30px 0;

	.count-blog,
	.count-blogger,
	.count-forum,
	.count-photo {
		transition: background 0.2s ease, color 0.2s ease;

		&:active,
		&:hover {
			background: rgba(0,0,0,0.5);

			a {
				color: #fff;
				background: transparent;
			}
		}
	}
}

.sidebar {
	padding-top: 20px;
	font-size: 14px;
	font-size: 1.4rem;
}

.blog .sidebar {
	padding-top:0;
}
// extensions

form.tb-xs {
	.btn,
	input {
		padding: @padding-xsmall-vertical @padding-xsmall-horizontal;
		font-size: @font-size-xsmall;
		border-radius: @border-radius-xsmall;
		height: 2.3em;
	}
}

body {
	font-family: @font-family-sans-serif;
	color: #555;
	font-size: 16px;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: "Segoe UI", @font-family-sans-serif;

	&.tb {
		color: @tb-dark-orange;
	}

	&.bar {
		border-bottom: 3px solid @tb-border-gray;
		padding-bottom: 0.5em;
	}
}
/* default stylings for cms content. */
.cms_block {
	.h1,
	.h2,
	.h3,
	.h4,
	.h5,
	.h6,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-family: "Segoe UI", @font-family-sans-serif;
		color: @tb-dark-orange;
	}

	h1 {
		border-bottom: 3px solid @tb-border-gray;
		padding-bottom: 0.3em;
	}

	nav {
		margin: 10px auto;
		border: 1px solid #ddd;
		padding: 10px;
		background: #f5f5f5;
	}
}

iframe {
	width: 100%;
}

iframe.comment {
	height: 400px;
}

.breadcrumb {
	border: 1px solid @tb-border-gray;
	margin-bottom: 0;
}

.tb-padded {
	padding: @padding-base-horizontal 0;
}

.tb-columns {
	margin: 15px;
	column-width: 25em;
	column-gap: 1em;
	-moz-column-width: 25em;
	-moz-column-gap: 1em;
	-webkit-column-width: 25em;
	-webkit-column-gap: 1em;

	&.tb-mid {
		column-width: 20em;
		column-gap: 1em;
		-moz-column-width: 20em;
		-moz-column-gap: 1em;
		-webkit-column-width: 20em;
		-webkit-column-gap: 1em;
	}

	&.tb-narrow {
		column-width: 15em;
		column-gap: 1em;
		-moz-column-width: 15em;
		-moz-column-gap: 1em;
		-webkit-column-width: 15em;
		-webkit-column-gap: 1em;
	}

	&.tb-xs-narrow {
		column-width: 10em;
		column-gap: 1em;
		-moz-column-width: 10em;
		-moz-column-gap: 1em;
		-webkit-column-width: 10em;
		-webkit-column-gap: 1em;
	}

	&.tb-xxs-narrow {
		column-width: 7em;
		column-gap: 1em;
		-moz-column-width: 7em;
		-moz-column-gap: 1em;
		-webkit-column-width: 7em;
		-webkit-column-gap: 1em;
	}
}

.minifeed {
	display: inline-block;
	width: 100%;
	padding: 10px;
	font-size: 14px;
	font-size: 1.4rem;

	.minifeed-title {
		font-size: @font-size-large;
	}

	.minifeed-photo {
		float: right;
		margin: 5px;
		border: 1px solid @tb-border-gray;
		padding: 5px;
		background: @tb-background-gray;
	}

	.minifeed-link {
		float: right;
	}
}

.minifeed:nth-child(odd) {
	background: @tb-background-gray;

	.minifeed-photo {
		background: #fff;
	}
}

#mapcontainer .minifeed {
	background: transparent!important;
}

.admin .minifeed {
	.minifeed-title {
		font-size: @font-size-small;
	}
}

.trip-container {
	max-height: 20em;
	overflow-y: auto;

	.trip {
		display: block;
		padding: 0 5px;

		.trip_date {
			font-size: @font-size-small;
			color: #999;
		}

		&:nth-child(even) {
			background: #f5f5f5;
		}
	}
}
// remove from bootstrap

a:active,
a:focus {
	outline: 0;
	border: 0;
}

::-moz-focus-inner {
	border: 0;
}

h1.tb-banded {
	border-bottom: 5px solid @tb-border-gray;
	padding: 0 0 7px 15px;
	color: @tb-dark-orange;
}

div#hometop h1.tb-banded {
	/* exception */
	padding: 0 0 7px;
	margin-top: 0;
	font-size: 30px;
}

h1,
h1.sans {
	font-weight: 400;
}

h2 {
	font-weight: 400;
}

header {
	.layer1 {
		background-image: url("/pix/v5/layer1.png");
		background-size: cover;
		background-attachment: scroll;
		background-repeat: repeat-x;
		background-position: 0;
		margin: 0;
	}

	.tint {
		background: linear-gradient(to bottom, rgba(0,30,60,0.8), rgba(0,30,120,0.2) );
	}
	margin: 0;
	border: 0;
	background-size: cover;
	background-color: #fff;
	background-attachment: scroll;
	scroll-behavior: smooth;
	color: #ddd;

	.input-sm {
		border: 1px solid #444;
	}

	.tb-logo {
		margin: 16px 0 0;
		display: block;
		width: auto;
	}

	.tb-logo-small {
		margin: 13px 0 0 5px;
		display: block;
		width: auto;
	}

	ul li {
		color: @tb-mid-blue;
	}

	button.navbar-toggle {
		color: #eee;
		background-color: rgba(0,0,0,0.6);
	}
}

ul.tb-continents {
	margin: 0 auto;

	li {
		a {
			color: #eee;
			font-size: @font-size-base;
			padding: 0 0.4% 3px;
			margin: 0;
			transition: 0.2s background ease,.2s color ease;
			font-weight: 400;
			white-space: nowrap;

			&:hover {
				color: #f5f5f5;
				background: @tb-light-blue;
			}
		}
	}
}

.navbar-collapse ul {
	margin: 0;

	li {
		a {
			&:active,
			&:focus,
			&:hover {
				color: #fff;
				background: #111;
			}
		}
	}
}

.control-panel {
	background: rgba(0,0,0,0.6);

	li a {
		color: #bbb;
		padding: 10px;
	}

	li:hover a {
		color: #eee;
		background: rgba(0,0,0,0.8);
	}
	border-radius: 0 0 5px 5px;
	margin-top: 0;
}

.main-menu {
	line-height: 1rem;
	background: rgba(0,0,0,0.3);

	li {
		display: inline;
	}

	li a {
		color: #bbb;
		padding: 6px 15px 8px;
		font-size: 1.4rem;
		line-height: 1.2em;
	}

	ul > li > a {
		color: #eee;

		&:active,
		&:focus,
		&:hover {
			background: rgba(0,0,0,0.8);
			color: #fff;
		}
	}
	border-radius: 5px 5px 0 0;
	margin-top: 12px;
}

.navbar-nav {
	> li > a {
		color: #ddd;

		&:focus,
		&:hover {
			color: #eee;
			background-color: rgba(0,0,0,0.8);
		}
	}

	> .active > a {
		&,
		&:focus,
		&:hover {
			color: #eee;
			background-color: rgba(0,0,0,0.8);
		}
	}

	> .disabled > a {
		&,
		&:focus,
		&:hover {
			color: #ddd;
			background-color: rgba(0,0,0,0.3);
		}
	}
}

.tb-wrapper {
	// background: #f5f5f5;
	// background: #467;
	color: #eee;
	text-align: center;
	padding: 4px 0 0;

	.container {
		margin: 0 auto;
		line-height: 2.4em;
	}

	.nav .open > a,
	.nav .open > a:focus,
	.nav .open > a:hover {
		background-color: transparent;
		border-color: #fff;
	}

	.navbar-inner {
		-webkit-border-radius: 0;
		-moz-border-radius: 0;
		border-radius: 0;
	}

	button.navbar-toggle {
		background: @tb-mid-blue;
		color: #fff;

		.fa {
			color: white;
		}
	}

	ul li ul.dropdown-menu,
	ul li:active ul.dropdown-menu,
	ul li:hover ul.dropdown-menu {
		background: #fff;

		li {
			background: #fff;
			color: #777;

			a {
				background: transparent;
				color: #777;
			}
		}

		li:active,
		li:focus,
		li:hover {
			color: #777;

			a {
				background: #e5eeff;
				color: #555;
			}
		}
	}
}

.navbar-compact {
	ul {
		margin: 0;

		li a {
			padding: 0 20px 0 0;

			&:hover {
				background: transparent;
				color: @tb-dark-orange;
			}
		}
	}
}
/* hover the navbar menus */
ul.nav li.dropdown:hover > ul.dropdown-menu {
	display: block;
}

ul.nav li.dropdown > ul li a {
	padding: 10px;
	color: #777;

	&:hover {
		background: #e5eeff;
	}
}

footer.footer {
	margin: 0;
	border: 0;
	padding: 0;
	border-radius: 0;
	background: #356;
	color: @tb-border-gray;
	padding: 15px 0 0 15px;
}

footer.footer div.container ul li {
	list-style-type: none;
}

footer.footer a {
	color: @tb-border-gray;
}

footer.footer a:hover {
	color: #fff;
}

footer.footer ul.social-links {
	float: right;
	margin-top: -32px;

	li {
		display: inline;

		a {
			width: 34px;
			height: 34px;
			display: inline-block;
			padding-top: 4px;
			margin-left: 10px;
			border-radius: 50%;
			border: 2px solid #fff;
			background: #999;
			vertical-align: center;
			text-align: center;
			transition: background 0.4s;

			&.rss {
				background: @rss-orange;

				&:active,
				&:hover {
					background: darken(@rss-orange, 10%);
				}
			}

			&.twitter {
				background: @twitter-blue;

				&:active,
				&:hover {
					background: darken(@twitter-blue, 10%);
				}
			}

			&.facebook {
				background: @facebook-blue;

				&:active,
				&:hover {
					background: darken(@facebook-blue,10%);
				}
			}
		}
	}
}

div.like-us {
	height: 34px;
	margin: 10px 0 0;
	overflow: hidden;
	text-align: center;
	width: 98px;

	div {
		float: left;
		padding: 5px;
	}

	&.loading {
		border: 1px solid #eee;
	}
}

form.form-inline input[type="password"],
form.form-inline input[type="text"] {
	width: 160px;
}

form.navbar-search {
	margin: 4px -10px 4px 0;
	color: #bbb;
	transition: color 1s ease;
	line-height: 1.2em;

	&:focus,
	&:hover {
		color: #ccc;
	}

	.fa.fa-search {
		position: absolute;
		top: 4px;
		right: 18px;
	}

	&:focus .fa.fa-search,
	&:hover .fa.fa-search {
		color: #aaa;
	}

	input.submit-search {
		position: absolute;
		top: 0;
		right: 15px;
		height: 28px;
		width: 28px;
		border: 0;
		background: transparent;
		z-index: 100;
	}

	input[type="search"] {
		border: 0; //1px solid #777;
		width: 150px;
		padding-right: 20px;
		transition: width 1s ease, background 1s ease;
		background: #f8f8f8;
		color: #999;
	}

	input[type="search"]:focus,
	input[type="search"]:hover {
		width: 300px;
		background: #fff;
	}
}

.btn-group .btn-tb,
.btn-group-vertical .btn-tb,
.btn-tb {
	color: #fff;
	background-color: @tb-orange;
	border-color: @tb-dark-orange;

	&:active,
	&:focus,
	&:hover {
		color: #fff;
		background-color: @tb-dark-orange;
		border-color: @tb-dark-border-orange;
	}

	&.disabled,
	&.disabled:active,
	&.disabled:hover,
	&[disabled],
	&[disabled]:active,
	&[disabled]:hover {
		background-color: #428bca;
		border-color: #428bca;
	}
}

.margin-top-lg {
	margin-top: 20px;
}

br.clear,
br.clearfix {
	clear: both;
}

div.tb-center {
	margin: 0 auto;
}

h1.tb-title {
	color: #ddd;
	clear: both;
	margin: -10px 0 0 33px;
	padding: 0;
}

ul.tb-list {
	li {
		list-style-type: none;
		width: 100%;
		border-bottom: 1px solid @tb-border-gray;
		padding: 5px;
		display: inline-block;

		img {
			float: left;
			margin-right: 10px;
			margin-top: 5px;
		}
	}
	margin: 0;
	padding: 0;
}

ul.tb-blog-list {
	li {
		list-style-type: none;
		width: 100%;
		border-bottom: 1px solid @tb-border-gray;
		padding: 5px;

		a {
			width: 60px;

			img {
				float: left;
				max-height: 48px;
				max-width: 50px;
				padding: 3px;
				border: 1px solid @tb-border-gray;
				background: @tb-background-gray;
			}
		}
	}
	margin: 0;
	padding: 0;
}

div.list {
	padding: 10px;
	border-bottom: 1px solid @tb-border-gray;
}

.highlight {
	margin-top: 15px;
	margin-bottom: 5px;
	padding-bottom: 5px;
	border-bottom: 1px solid #ddd;
	font-size: 14px;
	font-size: 1.4rem;

	.highlight-footer {
		color: #999;
	}
}

.highlights,
.hintsntips {
	li {
		list-style-type: none;
		width: 100%;
		padding: 5px;

		img {
			float: left;
			margin-right: 10px;
			margin-top: 5px;
		}
	}

	li:nth-child(odd) {
		background: @tb-background-gray;
	}

	ul {
		margin: 0;
		padding: 0;
	}

	&.highlights-large {
		li {
			padding: 10px;
			font-size: 1.6rem;
			line-height: 2rem;
		}

		.small {
			margin-left: 10px;
		}
	}

	&.highlights-alt {
		li {
			color: @tb-mid-blue;

			.small {
				color: @tb-blue;
			}
		}
	}
}

div#login-form {
	padding-top: 13px;
}

hr.tb-header-hr {
	clear: both;
	border-top: 1px solid #aaa;
	margin: 15px 0 5px;
}

body.front ul.nav-tabs li {
	min-width: 20%;
	max-width: 33%;
}

.nav-tabs > li > a {
	padding: 10px 14px;
}

span.tb-tab {
	text-align: left;
}

span.tb-tab-title {
	display: block;
	font-size: 16px;
	font-weight: 600;
	color: @tb-dark-orange;
}
// Blog display

.tb-border {
	border-width: 0 0 5px 0;
	border-color: @tb-blue;
	border-style: solid;
	margin: 15px 0 45px;
}

.blog-title-panel {
	padding: 5px 10px 10px 15px;
	margin: 20px 0 0;
	background: @tb-blue;
	border-radius: 4px 4px 0 0;

	.blog-title {
		color: #eee;
		font-size: 2.6rem;

		&:hover {
			color: #f5f5f5;
		}
	}
}

.blog-panel,
.blogger-panel {
	padding: 10px 10px 0;
	background: #fff;
	border-right: 1px solid #ddd;
	border-left: 1px solid #ddd;

	.location-bar {
		margin: 0 5px;
	}

	.title {
		font-size: @font-size-h2;
		padding-left: 8px;
		margin-top: 0;
		display: block;
		border-bottom: 2px solid @tb-border-gray;
	}

	.blog-body {
		clear: both;
		padding-top: 10px;

		.blog-body {
			.blogger-pic {
				margin-top: 20px;
			}
		}
	}

	.blog-body.right {
		.blogger-pic {
			float: right;
			margin-left: 15px;
		}
	}

	.blog-body.left {
		.blogger-pic {
			float: left;
			margin-right: 15px;
		}
	}

	.photo {
		margin: 0 0 15px;
		border: 1px solid @tb-border-gray;
		background: @gray-lighter;
		text-align: center;
		padding: 10px;
		position: relative;
		max-width: 322px;

		.name {
			margin-top: 10px;
			display: inline-block;
		}
	}

	.photo-wrap {
		margin: 0 auto;
		display: table;
		width: auto;
	}

	.thumb {
		float: left;
		margin-top: 10px;

		a.ptl {
			display: inline-block;
			position: relative;
			border: 1px solid @tb-border-gray;
			background: @gray-lighter;
			text-align: center;
			padding: 4px;
			// override
			img.display {
				padding: 2px;
			}
		}
	}

	.blog-body.right .thumb .ptl {
		float: left;
		margin-right: 15px;
	}

	.blog-body.left .thumb .ptl {
		float: right;
		margin-left: 15px;
	}

	.date {
		display: block;
		color: @tb-dark-orange;
	}

	.small {
		color: #999;
	}

	.blog-spacer {
		.hidden();
		.visible-xs();
	}

	.blog-info-left {
		.pull-left();
		.hidden-xs();
		margin-right: 10px;
	}

	.blog-info-right {
		.pull-right();
		.text-right();
		.hidden-xs();
	}
}

.blog-footer {
	clear: both;
	background: #79a;
	padding: 5px 15px 0;
	color: #fff;
}

.blogger-panel {
	border: none;
	border-radius: 0;
	padding: none;
	margin-top: 0;

	.photo {
		margin: 0 20px 20px 0;
	}
}

.blog-container {
	display: block;
	padding: 10px;
	border: 1px solid @tb-border-gray;
	margin-bottom: 15px;
	font-size: @font-size-h5;

	&.saved {
		background: #ffe;
	}

	&.published {
		color: #999;
	}

	&.deleted {
		background: #f5f5f5;
	}
}

.blogger-pic {
	display: inline-block;
	text-align: center;
	padding: 5px 3px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	margin-bottom: 10px;
	line-height: 1.2em;

	&.pull-left {
		margin-right: 10px;
	}

	&.pull-right {
		margin-left: 10px;
	}
	width: 114px;
	height: 140px;
	position: relative;

	.image {
		display: inline-block;
		background-position: center;
		background-size: cover;
		height: 90px;
		width: 90px;
		min-height: 70px;
		min-width: 70px;
		border-radius: 50%;
		color: #fff;
	}

	&.blogger-minipic {
		height: 95px;
		width: 90px;

		.image {
			width: 70px;
			height: 70px;
		}
		margin-bottom: 5px;
	}
}

.blog-date {
	color: @tb-dark-orange;
	font-size: @font-size-h4;
}

.tb-photo-list {
	text-align: center;
	column-width: 8em;
	column-gap: 1em;
	-moz-column-width: 8em;
	-moz-column-gap: 1em;
	-webkit-column-width: 8em;
	-webkit-column-gap: 1em;
	padding: 0;

	a {
		font-size: @font-size-xsmall;
		display: inline-block;
		box-sizing: box-model;
		padding: 9px;
		margin-bottom: 15px;
		background: @tb-background-gray;
		border: 1px solid @tb-border-gray;

		img {
			width: 100%;
			border: 1px solid @tb-border-gray;
			background: #fff;
			padding: 3px;
			margin-bottom: 5px;
		}
	}
}

.response {
	.photo-standalone {
		width: auto;
		margin-right: 15px;
	}

	.boxed {
		border: 0;
		border-bottom: 1px solid #ddd;
		background: #fff;

		&.pull-left {
			text-align: center;
			padding: 0;
			width: 122px;

			.blogger-pic {
				display: block;
				margin: 0 auto;
			}
		}
	}
}

div.awards a {
	display: block;
	border: 1px solid @tb-border-gray;
	background: @gray-lighter;
	text-align: center;
	padding: 3px;
	margin: 0 6px 6px 0;
	width: 100px;
	float: left;
}

.comments {
	font-size: 14px;
	font-size: 1.4rem;
}

.comment-display {
	width: 100%;
	padding: 15px;
	font-size: 14px;
	font-size: 1.4rem;
	display: inline-block;
	clear: both;

	&.author-response {
		padding-left: 10%;
	}

	&.admin {
		border-width: 1px 1px 0 1px;
		border-color: @tb-border-gray;
		border-style: solid;
		margin: 0;
	}

	.photo_featured {
		width: auto;
		float: right;
		margin: 0 0 10px 10px;
	}

	img {
		border: 1px solid @tb-border-gray;
		background: @tb-background-gray;
		padding: 3px;
	}

	.from {
		width: 100px;
		margin: 0 10px 10px 0;
		float: left;
		text-align: center;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	&:nth-child(even) {
		background: @tb-background-gray;

		img {
			background: #fff;
		}
	}
}

span.blog-pubdate {
	color: #999;
}

div.article-footer {
	margin: 5px 0;
	padding: 5px 0;
	color: #999;
	border-bottom: 1px solid @gray-lighter;
}

div.article-head,
h2.tb-banded {
	color: @tb-dark-orange;
	font-size: @font-size-h3;
	border-bottom: 2px solid @tb-border-gray;
	margin: 0 0 10px;
	padding: 10px 0 0 10px;
}

h2.tb-banded {
	line-height: 24px;
	margin: 10px 0;
	padding: 10px 0 5px 10px;
}

div.article-head a {
	font-size: @font-size-h3;
}
// TOOD -- is this a default

.forum-post {
	position: relative;
}

.editnote {
	&:extend(.small);
}

.bottom-right {
	position: absolute;
	bottom: 10px;
	right: 0;
}

.reply-btn {
	bottom: -7px;
}

.bottom-left {
	position: absolute;
	bottom: 10px;
	left: 0;
}

.top-right {
	position: absolute;
	top: 7px;
	right: 4px;
}

.top-left {
	position: absolute;
	top: 7px;
	left: 4px;
}

.blogger-pic {
	.bottom-right {
		position: absolute;
		bottom: 5px;
		right: 5px;
	}

	.bottom-left {
		position: absolute;
		bottom: 5px;
		left: 5px;
	}
}

.boxed {
	display: inline-block;
	width: 100%;

	.highlight,
	code {}

	h4 {
		margin-top: 0;
		margin-bottom: 5px;
	}

	p:last-child {
		margin-bottom: 0;
	}
	margin: 10px auto;
	border: 1px solid #ddd;
	padding: 10px;
	background: #f5f5f5;

	&.pull-left {
		margin-right: 15px;
		width: auto;
	}

	&.pull-right {
		margin-left: 15px;
		width: auto;
	}
}

.press-img {
	text-align: center;
	display: block;
	margin: 10px auto;
	border: 1px solid #ddd;
	padding: 10px;
	break-inside: avoid-column;
	display: inline-block;

	img {
		max-width: 100%;
	}
}
/* Carousel */
.carousel.front {
	max-width: 810px;
	height: 490px;
	overflow: hidden;
	margin: 0 auto;

	.carousel-indicators {
		display: none;
	}

	.carousel-control.left,
	.carousel-control.right {
		background-color: transparent;
		background-image: none;
	}

	.item {
		max-width: 810px;
		margin: 0;
		padding: 10px;
		background: #333;
		height: 490px;
		overflow: hidden;

		.img-info {
			padding: 5px;

			h3 {
				margin: 11px 0 0;
				color: #ddd;
				text-overflow: ellipsis;
				white-space: nowrap;
				font-size: 2.8rem;
				overflow: hidden;
				width: 100%;
				height: 40px;
				font-family: 'Arapey',Georgia,Cambria,"Times New Roman",Times,serif;
				font-size: 2.8rem;
				font-style: italic;
			}
		}

		.img-credit {
			display: block;
			text-align: right;
			float: right;
			padding: 2px 6px;
			font-family: 'Arapey',Georgia,Cambria,"Times New Roman",Times,serif;
			font-size: 14px;
			font-style: italic;
			color: #bbb;

			a {
				color: #ddd;
				font-family: @font-family-sans-serif;
			}

			img {
				padding: 2px;
				border: 1px solid @tb-border-gray;
				max-width: 60px;
				max-height: 50px;
				background: #ddd;
				float: right;
				margin-left: 10px;
			}
		}

		.img-box {
			position: relative;
			background-color: #333;
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
			transition: background 0.6s ease-in 0.6s;
			height: 415px;
			overflow: hidden;
			border: 3px solid #eee;

			a {
				display: block;

				img {
					margin: 0;
					padding: 0;
					height: 450px;
					width: 100%;
					display: block;
					position: relative;
					top: 0;
				}
			}
		}
	}
}
/* PHOTO PAGE STYLES */
.photo-wrapper {
	margin: 20px auto 10px;
}

a.view_photo_title:hover {
	color: #036;
}

a.view_photo_title {
	clear: both;
	float: left;
	position: relative;
	width: 100%;
	text-align: center;
	font-style: italic;
	font-size: 22px;
	font-size: 2.2rem;
	font-weight: 400;
	color: #058;
	margin: 10px 0 0;
}

div.display-photo-info {
	position: relative;
	text-align: left;
	font-size: 14px;
	font-size: 1.4rem;
	width: 48%;
	margin: 10px 0 0;
}

div.display-photo-info.right {
	float: right;
	width: 48%;
	text-align: left;
	margin-left: 2%;
	padding-left: 2%;
	border-left: 1px dotted #ddd;
}

span.display-photo-blog-link {
	font-size: 18px;
	font-size: 1.8rem;
	line-height: 30px;
	line-height: 3rem;
}

hr.display-photo-hr {
	float: left;
	position: relative;
	width: 99%;
	border: 0;
	border-top: 1px dotted #ccc;
	margin: 10px 0;
}

img.display-photo-flag {
	clear: both;
	float: left;
	position: relative;
	height: 40px;
	width: 80px;
	border: 1px solid @tb-border-gray;
	background-color: @tb-border-gray;
	margin: 0 10px 0 0;
	padding: 5px;
}

span.description {
	color: #666;
	width: 95%;
}

span.display_photo_from {
	display: block;
	min-height: 18px;
}

.photo-container {
	position: relative;
	display: block;
	float: none;
	clear: both;
	text-align: center;
	vertical-align: top;
	width: auto;
	margin: 0 auto;

	.photo-name {
		font-weight: 400;
		text-align: center;
		border-bottom: 1px dotted #ccc;
		margin: 15px auto;
		padding: 15px;
		color: @tb-dark-orange;
	}

	.position-left {
		position: relative;
		float: right;
		left: -50%;
	}

	.position-right {
		position: relative;
		left: 50%;
	}

	.view-photo {
		display: block;
		margin: 0 auto;
		width: auto;
		padding: 10px 8px;
		overflow: hidden;
		background: #222;

		img {
			padding: 5px;
			background: #fff;
			width: 100%;
			max-width: 100%;
		}
	}
}

.photo-standalone {
	display: inline-block;
	margin: 0 auto 15px;
	width: 100%;
	border: 1px solid #ccc;
	background: #f5f5f5;
	padding: 12px 10px;
	overflow: hidden;
	text-align: center;

	img.photo-view {
		background: #222;
		border: 6px solid #222;
		margin: 0 auto;
	}

	.img-credit {
		display: block;
		text-align: right;
		float: right;
		padding: 5px;
		text-align: center;

		.photo-info {
			max-width: 120px;
		}

		img {
			padding: 2px;
			border: 1px solid @tb-border-gray;
			max-width: 60px;
			max-height: 50px;
			background: #fff;
			float: right;
			margin-left: 10px;
		}
	}
}

.photo-for-blogger {
	max-width: 330px;
	margin: 15px 15px 15px 0;
}

.photo-rating {
	position: absolute;
	bottom: -30px;

	ul {
		margin: 0;
		padding: 0;
		list-style-type: none;
		text-align: center;

		li {
			display: inline;

			a {
				color: @tb-dark-orange;
			}
		}
	}
}

.moderate-image {
	position: absolute;
	bottom: -30px;
	right: 0;
	opacity: 0.2;
	transition: opacity 0.2s;

	&:hover {
		opacity: 1;
	}

	a.selected {
		background: #333;
	}
}

.comment-display .photo-standalone {
	float: right;
	max-width: 140px;
	margin-left: 10px;
}
/* TODO */
table.xinfo {
	width: 100%;
	border-collapse: collapse;
	margin: 0;
}

table.xinfo td {
	border: none;
	font-size: 1.1rem;
}

.flag-images {
	text-align: center;
	position: relative;
	background: @tb-border-gray;
	border: 1px solid @tb-border-gray;
	border-radius: 4px;
	font-size: 11px;
	font-size: 1.1rem;
	overflow: hidden;
	display: none;
	padding: 5px;
}

html.tb_user .flag_images {
	display: block;
}

.blogger.image {
	text-align: center;
	width: auto;

	a {
		img {
			background: @tb-background-gray;
			max-width: 100%;
			padding: 5px;
			border: 1px solid @tb-border-gray;
			transition: background 0.4s;
		}

		&:active,
		&:hover {
			img {
				background: #ddd;
			}
		}
	}
}

div.ads_adlinks,
div.ads_banner,
div.ads_large_rectangle,
div.ads_leader,
div.ads_links,
div.ads_medium_rectangle,
div.ads_skyscraper,
div.ads_small_rectangle,
div.ads_small_square,
div.ads_square,
div.ads_square,
div.ads_square_left,
div.ads_square_right,
div.ads_wide_skyscraper {
	margin: 15px auto;
	color: #999;
	font-size: 10px;
	font-size: 1rem;
	text-align: center;
	border: 1px solid #eee;
	padding: 2px 5px 10px;
	border-radius: 4px;
	background-color: #f8f8f8;
}

br.ads {
	clear: both;
}

.fl_right {
	float: right;
	margin-left: 15px;
}

.fl_left {
	float: left;
	margin-right: 15px;
}

.center {
	text-align: center;
}

.continent-info {
	a {
		img {
			margin: 3px;
			padding: 3px;
			border: 1px solid @tb-border-gray;
			background: @tb-background-gray;
		}
	}

	.map {
		margin: 0 auto;

		img {
			max-width: 100%;
			border: 0;
			background: transparent;
		}
	}
}

.country-info {
	a {
		img {
			margin: 3px;
			padding: 3px;
			border: 1px solid @tb-border-gray;
			background: @tb-background-gray;
		}

		img.map {
			float: right;
		}

		img.flag {
			float: left;
		}
	}
}

div.mini_photo a:hover {
	background: #fff;
}

div.mini_photo a img,
div.photo_style_tiny_no_float img,
table.blog_mini_list {
	border: 1px solid @tb-border-gray;
}

div.mini_photo {
	height: 173px;
	width: 143px;
	float: left;
	margin-left: 20px;
}

div.mini_photo div.mini_photo_wrapper {
	display: table-cell;
	vertical-align: middle;
	text-align: center;
	height: 173px;
	width: 144px;
	overflow: hidden;
}

div.mini_photo a {
	font-size: 11px;
	font-size: 1.1rem;
	font-weight: 400;
	display: block;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	background: @tb-border-gray;
	border: 1px solid @tb-border-gray;
	white-space: nowrap;
	overflow: hidden;
	padding: 5px;
}
/* blog display */
.blog {
	h2 {
		color: @tb-dark-orange;
		margin-bottom: 15px;
	}

	h3 {
		font-family: @font-family-serif;
	}

	.content img {
		max-width:100%;
	}
}

div.photo-blog {
	position: relative;
	background: @tb-background-gray;
	border: 1px solid @tb-border-gray;
	text-align: center;
	color: #666;
	font-size: 1.4rem;
	margin: 15px 15px 5px;
	padding: 9px;
	overflow: hidden;

	&.left {
		float: left;
		margin-left: 0;
	}

	&.right {
		float: right;
		margin-right: 0;
	}

	&.top {
		margin-top: 0;
	}

	&.extra {
		width: auto;
		max-width: 322px;
		height: 410px;
		margin: 15px;
		padding: 10px;
		float: left;
	}

	.name {
		display: block;
		font-size: 16px;
		font-size: 1.6rem;
		margin-top: 10px;
		clear: both;
	}
}

.copyright {
	border-radius: 5px;
	background: @tb-background-gray;
	border: 1px solid @tb-border-gray;
	padding: 20px;
	margin: 30px 0 10px;
	font-size: 12px;
	font-size: 1.2rem;
}
/* maps and stuff */
.large_map {
	text-align: center;
	margin-bottom: 15px;
	padding: 15px;

	img {
		border: 1px solid @tb-border-gray;
		background: @tb-background-gray;
		padding: 5px;
		max-width: 100%;
	}
}
/* CMS STUFF */
div.cms_block {
	position: relative;
	top: 0;
	left: 0;
	display: block;
	height: auto;
}

a.cms_edit {
	line-height: 11px;
	line-height: 1.1rem;
	border: 1px solid @tb-border-gray;
	font-size: 10px;
	font-size: 1rem;
	color: #999;
	position: absolute;
	top: -6px;
	right: -6px;
	display: block;
	z-index: 1;
	background: rgba(221,238,255,0.5);
	padding: 1px;
}

a.cms_edit:hover {
	background: #999;
	color: @tb-border-gray;
}

._snippet_label {
	position: relative;
	top: -10px;
	left: -10px;
	background: rgba(0,0,0,0.3);
	border: 1px solid #ccc;
	z-index: 800;
	clear: none;
	display: inline;
	padding: 8px;

	&:hover {
		background: #def;
		z-index: 900;
	}
}

#cms_details,
.cms-info,
pre.debug,
pre.dump,
pre.print {
	background: #f5f5f5;
	border: 1px solid #bbb;
	font-size: 10px;
	font-size: 1rem;
	line-height: 12px;
	line-height: 1.2rem;
	display: block;
	margin: 20px;
	padding: 6px;
	clear: both;
}
/* MCE textarea */
#blogtext_ifr {
	padding: 10px;
}

#tinymce {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: @tb-dark-orange;
	}

	h3 {
		font-family: @font-family-serif;
	}
}
/* WMM -- to be deprecated */
.wmm_map {
	font-size: 1rem;
	margin: 25px 0;
}

.wmm_map h1 {
	font-weight: 400;
	border-bottom: 2px solid @tb-border-gray;
	position: relative;
	padding: 7px 10px;
	color: @tb-dark-orange;
	clear: both;
	font-size: @font-size-h3;
}

.wmm_map img.wmm_map {
	max-width: 100%;
	width: 100%;
	height: auto;
	display: block;
	margin: 0 auto;
}

.wmm_map img.wmm_flag {
	height: 26px;
	width: 34px;
	border: 1px solid @tb-border-gray;
	background: @tb-background-gray;
	margin: 1px 3px;
	padding: 4px;
}

.wmm_map .wmm_flags,
.wmm_map .wmm_legend {
	margin: 0 10px;
	clear: both;
}

.wmm_map .wmm_label {
	float: left;
	margin: 5px 10px;
}

.wmm_sponsored {
	font-size: 12px;
	font-size: 1.2rem;
	position: relative;
	border-top: 1px dotted @tb-border-gray;
}

.video-panel {
	padding: 10px;
	border: 1px solid #ddd;
	background: #f5f5f5;
	margin-bottom: 15px;
}
/* Callouts */
.callout {
	margin-bottom: @line-height-computed;
	padding: @callout-padding;
	border-left: 3px solid @callout-border;
	border-radius: @callout-border-radius;

	.highlight,
	code {}

	h4 {
		font-size: 18px;
		font-weight: 500;
		line-height: 1.1;
		margin-top: 0;
		margin-bottom: 5px;
	}

	p:last-child {
		margin-bottom: 0;
	}
}

.callout-danger {
	background-color: @callout-danger-bg;
	border-color: @callout-danger-border;

	h4 {
		color: @callout-danger-text;
	}
}

.callout-warning {
	background-color: @callout-warning-bg;
	border-color: @callout-warning-border;

	h4 {
		color: @callout-warning-text;
	}
}

.callout-info {
	background-color: @callout-info-bg;
	border-color: @callout-info-border;

	b {
		color: @tb-mid-blue;
	}

	h4 {
		color: @callout-info-text;
	}
}

.callout-success {
	background-color: @callout-success-bg;
	border-color: @callout-success-border;

	b {
		color: @tb-mid-blue;
	}

	h4 {
		color: @callout-info-text;
	}
}

.callout-gray {
	background-color: #f5f5f5;
	border-color: #aaa;

	b,
	h4 {
		color: #777;
	}
}
/* JOIN FORM CUSTOMIZATIONS */
.account-info {
	margin: 10px 10px -30px -10px;
	float: right;
}

.account-info.error {
	color: red;
}

.account-info.ok {
	color: green;
}
/* use mixins */
.form-control.has-error {
	.form-control-focus(red);
}

.form-control.has-success {
	.form-control-focus(green);
}
/* customisations for BBCode */
.underline {
	text-decoration: underline;
}

.strikethrough {
	text-decoration: line-through;
}
/* PLACEHOLDER */
::-webkit-input-placeholder {
	/* WebKit browsers */
	color: #999;
}

:-moz-placeholder {
	/* Mozilla Firefox 4 to 18 */
	color: #999;
}

::-moz-placeholder {
	/* Mozilla Firefox 19+ */
	color: #999;
}

:-ms-input-placeholder {
	/* Internet Explorer 10+ */
	color: #999;
}

.ellipsis {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.warning {
	background-color: @callout-warning-bg;
	border-color: @callout-warning-border;
	color: @callout-warning-text;
}

.danger {
	background-color: @callout-danger-bg;
	border-color: @callout-danger-border;
	color: @callout-danger-text;
}

.info {
	background-color: @callout-info-bg;
	border-color: @callout-info-border;
	color: @callout-info-text;
}

.success {
	background-color: @callout-success-bg;
	border-color: @callout-success-border;
	color: @callout-success-text;
}
/* show and hide for user */
.admin-show,
.moderator-show,
.owner-show,
.user-show {
	display: none!important;
}

body.not-logged-in {
	.anon-hide,
	.user-show {
		display: none!important;
	}
}

body.logged-in {
	.anon-show,
	.user-hide {
		display: none!important;
	}
}

body.admin-user {
	.admin-hide {
		display: none!important;
	}

	.admin-show {
		display: block!important;
	}
}

body.moderator-user {
	.moderator-hide {
		display: none!important;
	}

	.moderator-show {
		display: block!important;
	}
}

body.owner {
	.owner-hide {
		display: none!important;
	}

	.owner-show {
		display: block!important;
	}
}
// Fade transition for carousel items

.carousel {
	.item {
		left: 0 !important;
		.transition(opacity .8s); //adjust timing here
	}

	.carousel-control {
		background-image: none; // remove background gradients on controls
	}
	// Fade controls with items
	.next.left,
	.prev.right {
		opacity: 1;
		z-index: 1;
	}

	.active.left,
	.active.right {
		opacity: 0;
		z-index: 2;
	}
}
/* MEDIA OVERRIDES */
/* bigger than tiny screens */
@media (min-width: 600px) {
	.blog-panel,
	.blogger-panel {
		.date {
			float: left;
		}

		.location-bar {
			text-align: right;
			padding-left: 0;
		}

		.blog-body {
			.blogger-pic {
				margin-top: 0;
			}
		}

		.blog-body.right {
			.photo {
				float: left;
				margin-right: 15px;
			}
		}

		.blog-body.left {
			.photo {
				float: right;
				margin-left: 15px;
			}
		}

		.blogger-pic {
			margin-top: 0;
		}
	}
}
@media (max-width: 600px) {
	.col-lg-1,
	.col-lg-10,
	.col-lg-11,
	.col-lg-12,
	.col-lg-2,
	.col-lg-3,
	.col-lg-4,
	.col-lg-5,
	.col-lg-6,
	.col-lg-7,
	.col-lg-8,
	.col-lg-9,
	.col-md-1,
	.col-md-10,
	.col-md-11,
	.col-md-12,
	.col-md-2,
	.col-md-3,
	.col-md-4,
	.col-md-5,
	.col-md-6,
	.col-md-7,
	.col-md-8,
	.col-md-9,
	.col-sm-1,
	.col-sm-10,
	.col-sm-11,
	.col-sm-12,
	.col-sm-2,
	.col-sm-3,
	.col-sm-4,
	.col-sm-5,
	.col-sm-6,
	.col-sm-7,
	.col-sm-8,
	.col-sm-9,
	.col-xs-1,
	.col-xs-10,
	.col-xs-11,
	.col-xs-12,
	.col-xs-2,
	.col-xs-3,
	.col-xs-4,
	.col-xs-5,
	.col-xs-6,
	.col-xs-7,
	.col-xs-8,
	.col-xs-9 {
		padding: 0 3px;
	}

	.blog-panel,
	.blogger-panel {
		padding: 0;
		border: 0;

		.blog-spacer {
			.hidden();
		}
	}

	.photo,
	.photo-blog {
		float: none!important;
		clear: both;
	}

	.center-xs {
		margin-left: auto !important;
		margin-right: auto !important;
		display: block;
	}
}
/* small display bootstrap */
@media (max-width: 768px) {
	body {
		max-width: 100%;
		overflow-x: hidden;
	}

	header {
		text-align: center;

		button {
			color: #ddd;
			background-color: rgba(0,0,0,0.6);
		}

		.tint {
			height: 117px;
		}
	}

	ul.tb-continents {
		margin: 0 auto;

		li {
			a {
				font-size: @font-size-base;
			}
		}
	}

	div#login-form {
		clear: both;
		float: none;
		margin: auto 0;
	}
}
@media (min-width: 1500px) {
	div.container {
		max-width: 1500px;
	}
}
@media (max-width: @screen-xs-max) {
	.pull-xs-left {
		float: left;
	}

	.pull-xs-right {
		float: right;
	}
}
@media (min-width: @screen-sm-min) {
	.pull-sm-left {
		float: left;
	}

	.pull-sm-right {
		float: right;
	}

	.sidebar {
		border-left: 1px dotted rgba(0, 0, 0, 0.1);
	}
}
